import {
    Box, Card, Layer, Text, TextInput
} from "grommet";
import { Checkmark, Close } from "grommet-icons";
import React, { useState } from "react";
import { clearToken } from "../../services/storage";
import config from '../../config/config.json';

const bgColor = '#e6f3fe';
const ADMIN_PASS = 'xj!35*'

const SignIn = ({ toHomePage }) => {

    const [popup, setPopup] = useState(false);
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);

    return (
        <Box width={'full'} height='100vh' align='center' justify="center">
            {popup &&
                <Layer style={{background: 'transparent'}}>
                    <Box width={'large'} round={{size: 'medium'}} background='#fff'>
                        <Box justify="end" pad={'xsmall'} width='medium' onClick={() => setPopup(false)}>
                            <Close color="brand"/>
                        </Box>
                        <Box align="center" justify="center" direction="row" pad={'medium'} gap='medium'>
                            <TextInput value={password} type='password' onChange={event => setPassword(event.target.value)}
                                placeholder='Votre mot de passe admin'
                                size="xxlarge"
                            />
                            <Card pad={'small'} background='#033076' onClick={() => {
                                if (password !== ADMIN_PASS) {
                                    setError(true);
                                    return;
                                }
                                clearToken();
                                window.location.href = config.baseAPI;
                            }}>
                                <Checkmark />
                            </Card>
                        </Box>
                        <Box align="center" justify="center">
                            {
                                error && <Text size="small" color={'red'}>Mauvais mot de passe</Text>
                            }
                        </Box>
                    </Box>
                </Layer>

            }
            <Box align="center" gap="medium">
                <Card
                    background={'#72bb53'}
                    round={{ size: 'large' }}
                    align='center' justify="center"
                    pad='medium'
                    gap="xxlarge"
                    style={{ height: 420 }}
                    border={{ color: bgColor, size: 'small' }}
                    onClick={toHomePage}>
                    <Box pad={'large'}>
                        <Text color={'#fff'} style={{ fontSize: 100 }}>ACCÉDER AUX </Text>
                    </Box>
                    <Box pad={'large'}>
                        <Text color={'#fff'} style={{ fontSize: 100 }}>COMMANDES</Text>
                    </Box>

                </Card>
            </Box>
            <Box style={{ position: 'absolute', bottom: 10, left: 10 }}>

                <Card
                    border={{ color: bgColor, size: 'small' }}
                    pad='medium'
                    style={{ boxShadow: 'none' }}
                    onClick={() => {
                        setPopup(true);
                    }}>
                    <Text color={'#000'} style={{ fontSize: 40 }}>Admin</Text>
                </Card>
            </Box>
        </Box>
    )
}

export default SignIn;
/*

                <Card height={200}
                    style={{ position: 'fixed', bottom: 10 }}
                    background={frontColor}
                    pad='medium'
                    round={{ size: 'small' }}
                    border={{ color: bgColor, size: 'small' }}
                    onClick={() => {
                        setPopup(true);
                    }}>
                    <Text color={'#fff'} style={{ fontSize: 30 }}>Admin</Text>
                </Card>
                */