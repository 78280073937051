
import {
    Box, Button, CheckBox, Layer, Text, TextInput
} from "grommet";
import { Close, Save } from "grommet-icons";
import React, { useState } from "react";
import BlockUi from "react-block-ui";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from '../../config/config.json';
import { syncCatalogs, updateInventory, updateRestaurantStatus } from "../../services/service";
import { getAllCatsData, getAutoPrint, getLasUpdatedTime, getPrinterIp, isDispo, setAutoPrint, setDisponibility, setLastUpdatedTime, setPrinterIp } from "../../services/storage";
import { COLOR, FONT_SIZE } from "../../services/utils";
import AdminPopup, { AdminAccessButton } from "../sign_in/admin";
import DisponibilitePage from "./disponibilite";

const Header = ({ onClose, background }) => {

    return (
        <Box background={background || '#e6f3fe'} justify="between" align="center" width={'full'}
            direction='row'
        >
            <Box pad={'small'} style={{ left: 7, width: 10 }} >
            </Box>


            {isDispo() ?
                <Box pad={'small'} onClick={onClose} style={{ boxShadow: 'none' }}>
                    <Close size="large" color="#033076" />
                </Box> :
                <Box pad={'small'} style={{ boxShadow: 'none' }}>
                    <Close size="large" color="#e6f3fe" />
                </Box>
            }
        </Box>
    )
}

const PAGES = {
    disponibilite: 'disponibilite',
    printer: 'printer',
    settings: 'settings',
}

class SettingsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: PAGES.settings,
            loading: false,
            plats: [],
            closed: !isDispo(),
            isButtonDisabled: false,
            disable_inventory: false,
            showRappel: true,
            adminPopup: false
        }
    }

    componentDidMount = () => {
    }

    viewDisponibilite = () => {
        if (!isDispo()) return;
        this.setState({ page: PAGES.disponibilite });
    }

    changeDisponibilite = async () => {

        let cats = getAllCatsData();
        console.log(cats);
        let willClose;
        if (isDispo()) {
            willClose = true;
            // make all out of stock
        } else {
            willClose = false;
            // make all available
        }

        this.setState({ loading: true });
        try {
            for (let cat of cats) {
                let catId = cat.id;
                let inventories = cat.plats.map(plat => ({ "sku_id": plat.id, stock: willClose ? 0 : null }));
                await updateInventory(catId, inventories);
            }
            setDisponibility(!willClose);
            await updateRestaurantStatus(willClose);
            this.setState({ closed: willClose });
        } catch (err) {
            console.log(err);
        }
        this.setState({ loading: false, isButtonDisabled: true });
        setTimeout(() => this.setState({ isButtonDisabled: false }), 75000);
    }

    updateVersion = async () => {
        if (!isDispo()) return;
        let time = getLasUpdatedTime();
        let currentTime = new Date().getTime();
        let diff = (currentTime - time) / 1000;
        if (diff < 120) {
            this.showMessage("Veuillez attendre au moins 2m entre chaque rafraichir", 'warning');
            return;
        }
        try {
            this.setState({ loading: true });
            await syncCatalogs();
            window.location.href = config.baseAPI + '?reloaded=true';
            setLastUpdatedTime();
        } catch (error) {
            this.showMessage('Erreur inattendu', "error");
            console.log(error);
        }
        this.setState({ loading: false });
    }

    updateInventoryHook = (isOk) => {
        this.setState({ disable_inventory: true });
        setTimeout(() => {
            this.setState({ disable_inventory: false });
        }, 60000);
        if (isOk) {
            this.showMessage("Mis à jour avec succès", 'success');
        } else {
            this.showMessage("Erreur inattendu. Veuillez rafraichir l'app pour mettre à jour les catalogues.", "error");
        }
    }


    showMessage = (message, messageType) => {
        messageType = messageType || "info";
        toast[messageType](message);
    };

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading}>
                {this.state.page === PAGES.settings ?
                    <Box gap='medium' style={{ background: this.state.closed ? 'red' : 'none', opacity: this.state.closed ? '70%' : '100%', height: '100vh' }}>
                        <Header onClose={this.props.onClose} background={this.state.closed ? 'red' : undefined} />
                        {this.state.closed && this.state.showRappel &&
                            <Layer style={{ background: 'transparent' }}>
                                <Box width={'large'} background={COLOR.foreground_disabled} justify='center' align="center" pad={'medium'}
                                    gap='medium'
                                    round={{ size: 'medium' }}
                                >
                                    <Box style={{ position: 'absolute', left: 10, top: 10 }} onClick={() => this.setState({ showRappel: false })}
                                        round={{ size: 'medium' }} border={{ size: 'small', color: COLOR.dark_blue }}
                                        pad='small'
                                    >
                                        <Close color={COLOR.background_disabled} size='small' />
                                    </Box>
                                    <Box width={'full'} justify="center" align="center"
                                        style={{ padding: 10 }}
                                    >
                                        <Text color={COLOR.dark_blue} style={{ fontWeight: 'bold', fontSize: FONT_SIZE.large }} >
                                            = RESTAURANT EN PAUSE =
                                        </Text>
                                    </Box>

                                    <div style={{ padding: 40, display: 'flex' }}>

                                        <Text textAlign="center" style={{ fontWeight: "bold", lineHeight: '30px', color: COLOR.dark_blue, fontSize: FONT_SIZE.medium }}>
                                            Pensez à remettre vos produits hors stock au moment de la réouverture.
                                        </Text>
                                    </div>
                                </Box>
                            </Layer>
                        }

                        {this.state.adminPopup &&
                            <Layer style={{ background: 'transparent' }}>
                                <AdminPopup onClose={() => this.setState({ adminPopup: false })} />
                            </Layer>

                        }

                        <Box pad={'medium'} gap='large' align="start" >
                            <Box
                                style={{ boxShadow: 'none', height: '100%' }}
                                pad='small'
                            >
                                <Text color={'#033076'} style={{ cursor: 'pointer', fontSize: FONT_SIZE.xlarge }}
                                    onClick={this.viewDisponibilite}
                                >Disponibilité du stock</Text>
                            </Box>
                            <Box
                                style={{ boxShadow: 'none', height: '100%' }}
                                pad='small'
                            >
                                <Text color={'#033076'} style={{ cursor: 'pointer', fontSize: FONT_SIZE.xlarge }}
                                    onClick={this.updateVersion}
                                >Rafraîchir</Text>
                            </Box>
                            <Box
                                style={{ boxShadow: 'none', height: '100%' }}
                                pad='small'
                            >
                                <Text color={'#033076'} style={{ cursor: 'pointer', fontSize: FONT_SIZE.xlarge }}
                                    onClick={() => this.setState({ page: PAGES.printer })}
                                >Imprimante</Text>
                            </Box>
                        </Box>
                        <Box style={{ position: 'absolute', bottom: 20 }} pad='medium' justify="center" align="center" width={'full'}>
                            <Box
                                onClick={this.state.isButtonDisabled ? null : this.changeDisponibilite}
                                background={!this.state.closed ? '#e61610' : '#72bb53'}
                                justify='center' align="center"
                                pad='large'
                                round={{ size: 'large' }}
                                style={{ boxShadow: 'none', height: '100%', opacity: this.state.isButtonDisabled ? 0.5 : 1 }}
                            >
                                {!this.state.closed ?
                                    <Text style={{ fontWeight: 'bold', fontSize: FONT_SIZE.xlarge }} color={'#fff'}>Suspendre les commandes</Text> :
                                    <Text style={{ fontWeight: 'bold', fontSize: FONT_SIZE.xlarge }} color={'#fff'}>Reprendre les commandes</Text>
                                }
                            </Box>
                        </Box>
                    </Box> :
                    this.state.page === PAGES.printer ?
                        <Printer onClose={() => this.setState({ page: PAGES.settings })} /> :
                        <DisponibilitePage
                            updateInventoryHook={this.updateInventoryHook}
                            disable_inventory={this.state.disable_inventory}
                            onClose={() => this.setState({ page: PAGES.settings })}
                            showMessage={this.showMessage}
                        />

                }
                <AdminAccessButton
                    onOpen={() => this.setState({ adminPopup: true })}
                />


                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </BlockUi>

        )
    }
}

const Printer = ({ onClose }) => {
    const [value, setValue] = useState(getPrinterIp());
    const [success, setSuccess] = useState(false);
    const [_autoPrint, _setAutoPrint] = useState(getAutoPrint());
    const saveValue = () => {
        setPrinterIp(value);
        setSuccess(true);
        setTimeout(() => setSuccess(false), 3000);
    }
    return (
        <Box gap='medium' width={'full'} align="center" justify="center">
            <Header onClose={onClose} />
            <Box width={'large'} pad='medium' gap="medium" align="center" justify="center">
                <Text size="xlarge">
                    Veuillez entrer la valeur de "IP Address". Cette valeur est imprimée lors de la première impression de l'imprimante
                </Text>
                <TextInput
                    placeholder="192.168.10.246"
                    value={value}
                    onChange={event => setValue(event.target.value)}
                />
                <Box
                    style={{ boxShadow: 'none', height: '100%' }}
                    pad='small'
                    background={COLOR.light_blue}
                    direction='row' gap="small"
                    border={{ size: 'medium', color: COLOR.light_blue }}
                    round={{ size: 'medium' }}
                    onClick={saveValue}
                >
                    <Save color={COLOR.dark_blue} />
                    <Text color={'#033076'} style={{ fontSize: FONT_SIZE.medium }}
                    >Sauvegarder</Text>

                </Box>

                {success && <Text>Sauvegardé</Text>}

                {value ?
                    <Button label="Accepter la connexion à l'imprimante"
                        onClick={() => window.open(`https://${value}:8043/socket.io/1/?t=1683115219099`, '_blank')}
                    /> : null
                }

                <Box direction="row" justify="center" align="center" gap="small" pad={{top: 'medium'}}>
                    <Text color={'#033076'} style={{ fontSize: FONT_SIZE.small }}>
                        Dès la reception de commande :
                    </Text>
                    <Box>
                        <CheckBox
                            checked={_autoPrint}
                            label={'Auto print'}
                            onChange={event => {
                                _setAutoPrint(event.target.checked)
                                setAutoPrint(event.target.checked)
                            }}
                        />
                    </Box>
                </Box>

            </Box>
        </Box>
    )
}

export default SettingsPage;