import { navigate } from "gatsby";
import { Box, ResponsiveContext, Text } from "grommet";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../components/elements/layout";
import HistoryCommande from "../components/history_commandes";
import HomePage from "../components/home_page/index_v2";
import SettingsPage from "../components/settings_page";
import SignIn from "../components/sign_in";
import config from "../config/config.json";
import { printCommande } from "../services/print_service";
import {
  changeStatus,
  getAllCommandes,
  getLatestCommandes,
  getLocationProfile,
  getNewCommandes,
  ping,
  checkMigration,
  updateMigration,
} from "../services/service";
import {
  getAutoPrint,
  getLocation,
  getOrderToken,
  isDispo,
  isMigrated,
  setDisponibility,
  setMigrated,
} from "../services/storage";
import { COLOR } from "../services/utils";

const EVENTS = {
  nothing: "nothing",
  hasNewCommande: "hasNewCommande",
  signIn: "signIn",
  history: "history",
  processing: "processing",
  settings: "settings",
};

const showMessage = (message, type) => {
  toast.error(message, type || "info");
};

const containsCommande = (list, commande) =>
  list.map((c) => c.id).includes(commande.id);

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: EVENTS.signIn,
      newCommandes: [],
      selectedCommande: undefined,
      processingCommandes: [],
      terminatedCommandes: [],
      historyCommandes: [],
      lastLoadedHistory: undefined,
    };
  }

  componentDidMount = () => {
    this.redirecToNewApp();
    this.callLatestCommandes();
    let queryString = {};
    if (typeof window !== `undefined`) {
      queryString = window.location.search;
    }
    const urlParams = new URLSearchParams(queryString);
    let reloaded = urlParams.get("reloaded");
    if (reloaded) {
      if (isDispo()) {
        this.init();
      } else {
        this.setState({ event: EVENTS.settings });
      }
    }
  };

  redirecToNewApp = () => {
    let queryString = {};
    if (typeof window !== `undefined`) {
      queryString = window.location.search;
    }
    const urlParams = new URLSearchParams(queryString);
    let notRedirect = urlParams.get("do-not-redirect");
    if (notRedirect) return;
    const loc = getLocation();
    if (loc?.id) {
      navigate("https://app-new.meor.fr/migration?hubrise_id=" + loc.id);
    } else {
      navigate("https://app-new.meor.fr/migration");
    }
  };

  init = () => {
    this.setState({ event: EVENTS.nothing });
    this.callNewCommandes();
    this.verifyNewCommandes();
    this.callAllCommandes();
    this.updateCompletedCommande();
    this.countDownProssingCommandes();
    this.verifyConnected();
    this.verifyRestaurantStatus();
  };

  verifyRestaurantStatus = async () => {
    let token = getOrderToken();
    let locationRes = await getLocationProfile(token);
    if (locationRes.data.custom_fields.status) {
      setDisponibility(false);
      this.setState({ event: EVENTS.settings });
    }
  };

  verifyConnected = () => {
    ping();
    setTimeout(this.verifyConnected, config.REFESH_PING);
  };

  countDownProssingCommandes = () => {
    let processingCommandes = this.state.processingCommandes;
    let results = [];
    processingCommandes.forEach((c) => {
      let timeup = moment().diff(c.startProcessingTime, "minutes") > 15;
      if (timeup) {
        this.completeCommande(c);
      } else {
        results.push(c);
      }
      this.setState({ processingCommandes: results });
    });
    setTimeout(this.countDownProssingCommandes, 60 * 1000);
  };

  updateCompletedCommande = () => {
    let terminatedCommandes = this.state.terminatedCommandes;
    this.setState({
      terminatedCommandes: terminatedCommandes.filter(
        (p) => moment().diff(moment(p.created_at), "minutes") <= 20
      ),
    });
    setTimeout(
      () => this.updateCompletedCommande(),
      config.REFESH_PROCESSED_ORDERS
    );
  };

  callLatestCommandes = () => {
    getLatestCommandes()
      .then((res) => {
        let latestCommandes = res.data.filter((c) => c.status !== "new");
        if (latestCommandes.length > 0) {
          this.setState({
            terminatedCommandes: latestCommandes.filter(
              (s) => s.status === "completed"
            ),
            processingCommandes: latestCommandes.filter(
              (s) => s.status === "accepted"
            ),
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response?.status === 401) {
          navigate("/login");
        }
      });
  };

  callAllCommandes = async () => {
    try {
      let orders = await getAllCommandes();
      this.setState({
        historyCommandes: orders,
        lastLoadedHistory: new Date(),
      });
    } catch (err) {
      console.log("error when loading all commandes: ", err);
    }
  };

  callNewCommandes = () => {
    console.log("call new commandes");
    let processingCommandes = this.state.processingCommandes;
    let terminatedCommandes = this.state.terminatedCommandes;
    getNewCommandes()
      .then((res) => {
        let newCommandes = res.data.filter(
          (c) =>
            !containsCommande(terminatedCommandes, c) &&
            !containsCommande(processingCommandes, c)
        );
        // check if new commandes has passed 15m, if any, send it to completed commandes

        let forcedTerminatedCommandes = newCommandes.filter(
          (c) => moment().diff(moment(c.created_at), "minutes") > 15
        );
        for (let c of forcedTerminatedCommandes) {
          changeStatus(c, "completed");
          c.status = "completed";
          if (!terminatedCommandes.map((c) => c.id).includes(c.id)) {
            terminatedCommandes.push(c);
          }
        }

        newCommandes = newCommandes.filter(
          (c) => !terminatedCommandes.map((tc) => tc.id).includes(c.id)
        );

        console.log(
          terminatedCommandes,
          newCommandes,
          forcedTerminatedCommandes
        );

        this.setState({
          newCommandes,
          terminatedCommandes: terminatedCommandes.map((c) => c),
        });
        if (newCommandes.length > 0) {
          console.log("found new commandes", newCommandes.length);
        }
        if (getAutoPrint()) {
          for (const newC of newCommandes) {
            printCommande(
              newC,
              () => {},
              () => {}
            );
          }
        }
        setTimeout(() => this.callNewCommandes(), config.NEW_ORDER_INTERVAL);
      })
      .catch(() => {
        setTimeout(() => this.callNewCommandes(), config.NEW_ORDER_INTERVAL);
      });

    checkMigration().then(async (res) => {
      const migrations = res.data;
      console.log("migrations: ", migrations);
      if (migrations.length) {
        setMigrated(true);
        try {
          const migration = migrations[0];
          migration.migrated = true;
          await updateMigration(migration);
        } catch (error) {}
        const id = getLocation().id;
        navigate("https://app-new.meor.fr/migration?hubrise_id=" + id);
      }
    });
  };

  verifyNewCommandes = () => {
    if (
      this.state.event === EVENTS.history ||
      this.state.event === EVENTS.nothing
    ) {
      if (this.state.newCommandes.length > 0) {
        this.newCommandeHandler();
      }
    }
    setTimeout(() => {
      this.verifyNewCommandes();
    }, config.NEW_ORDER_INTERVAL_LOCALLY);
  };

  playAlert = () => {
    document.getElementById("audioPlayer").play();
  };

  newCommandeHandler = () => {
    this.setState({ event: EVENTS.hasNewCommande });
  };

  newCommandeProcessingHandler = async () => {
    let newCommandes = this.state.newCommandes;
    if (!newCommandes.length) return;
    let selectedCommande = newCommandes.pop();
    let processingCommandes = this.state.processingCommandes;
    processingCommandes.push(selectedCommande);
    changeStatus(selectedCommande, "accepted");
    selectedCommande.status = "accepted";
    selectedCommande.startProcessingTime = new Date();
    processingCommandes.sort((c1, c2) =>
      c1.startProcessingTime > c2.startProcessingTime ? -1 : 1
    );
    this.setState({
      event: EVENTS.nothing,
      selectedCommande,
      newCommandes,
      processingCommandes: processingCommandes.map((c) => c),
    });
  };

  completeCommande = (command) => {
    let terminatedCommandes = this.state.terminatedCommandes;
    changeStatus(command, "completed");
    command.status = "completed";
    terminatedCommandes.push(command);
    let processingCommandes = this.state.processingCommandes;
    processingCommandes = processingCommandes.filter(
      (c) => c.id !== command.id
    );
    this.setState({
      terminatedCommandes: terminatedCommandes.map((c) => c),
      processingCommandes,
    });
  };

  render() {
    return (
      <Layout style={{ justifyContent: "center", alignContent: "center" }}>
        <Box width="full" justify="center" align="center" alignSelf="center">
          <ResponsiveContext.Consumer>
            {(size) => (
              <Box width="full">
                {this.state.event === EVENTS.hasNewCommande && (
                  <NewCommandSignal
                    tap={() => this.newCommandeProcessingHandler()}
                    playAlert={this.playAlert}
                  />
                )}
                {this.state.event === EVENTS.signIn && (
                  <SignIn
                    toHomePage={() => {
                      console.log(isDispo());
                      if (isDispo()) {
                        this.playAlert();
                        this.init();
                      } else {
                        this.playAlert();
                        this.setState({ event: EVENTS.settings });
                      }
                    }}
                  />
                )}
                {this.state.event === EVENTS.nothing && (
                  <HomePage
                    toHistoryPage={() =>
                      this.setState({ event: EVENTS.history })
                    }
                    toSettingPage={() =>
                      this.setState({ event: EVENTS.settings })
                    }
                    processingCommandes={this.state.processingCommandes}
                    terminatedCommandes={this.state.terminatedCommandes}
                    processingCommande={this.state.selectedCommande}
                    validateCommande={this.completeCommande}
                    showMessage={showMessage}
                  />
                )}
                {this.state.event === EVENTS.history && (
                  <HistoryCommande
                    onLoad={(historyCommandes) =>
                      this.setState({
                        lastLoadedHistory: new Date(),
                        historyCommandes,
                      })
                    }
                    lastLoadedHistory={this.state.lastLoadedHistory}
                    lastCommandes={this.state.historyCommandes}
                    onClose={() => this.setState({ event: EVENTS.nothing })}
                  />
                )}
                {this.state.event === EVENTS.settings && (
                  <SettingsPage
                    onClose={() => this.setState({ event: EVENTS.nothing })}
                  />
                )}

                <Box style={{ display: "none" }}>
                  <audio
                    id="audioPlayer"
                    src={
                      "https://storage.googleapis.com/trustiway-bo-dev.appspot.com/alert_c5d02b629d/alert_c5d02b629d.mp3"
                    }
                  />
                </Box>
              </Box>
            )}
          </ResponsiveContext.Consumer>

          <Box style={{ height: 200 }} />

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Box>
      </Layout>
    );
  }
}

const NewCommandSignal = ({ tap, playAlert }) => {
  const [bgColor, setBgColor] = useState(COLOR.dark_blue);
  const [frontColor, setFrontColor] = useState(COLOR.light_blue);
  const [timeoutId, setTimeoutId] = useState();

  const flashLight = (_bgColor, _frontColor) => {
    let _bg = _bgColor || COLOR.dark_blue;
    let _front = _frontColor || COLOR.light_blue;
    setBgColor(_bg);
    setFrontColor(_front);
    playAlert();
    let _timeoutId = setTimeout(() => flashLight(_front, _bg), 2500);
    setTimeoutId(_timeoutId);
  };

  useEffect(() => {
    flashLight();
  }, []);

  const onTap = () => {
    clearTimeout(timeoutId);
    tap();
  };

  return (
    <Box width={"full"} background={bgColor} onClick={onTap}>
      <Box
        height={"100vh"}
        width="full"
        align="center"
        alignSelf="center"
        justify="center"
      >
        <Box align="center" gap="medium">
          <Text
            color={frontColor}
            style={{ fontSize: 100, fontWeight: "bold" }}
          >
            NOUVELLE
          </Text>
          <br />
          <Text
            color={frontColor}
            style={{ fontSize: 100, fontWeight: "bold" }}
          >
            COMMANDE !
          </Text>
          <br />
          <Text
            color={frontColor}
            style={{
              fontSize: 44,
              textDecoration: "underline",
              fontStyle: "italic",
            }}
          >
            CLIQUER POUR LA VOIR
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default IndexPage;
