

import {
    Avatar,
    Box, Layer, Text
} from "grommet";
import { Bike, Close, History, LinkNext, Menu } from 'grommet-icons';
import moment from "moment";
import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import config from '../../config/config.json';
import deliveroo from '../../images/deliveroo.png';
import ubereat from '../../images/ubereat.png';
import { syncCatalogs } from "../../services/service";
import { getAllCatsData, getLasUpdatedTime, getLocation, isDispo, setLastUpdatedTime } from "../../services/storage";
import { COLOR, FONT_SIZE, getTitle } from "../../services/utils";
import ProcessingCommande from '../processing_commande';

const isDeliveroo = c => c.created_by.includes('Deliveroo');

const Header = ({ toHistoryPage, toSettingPage, help, refresh }) => {

    const [currentTime, setCurrentTime] = useState('');

    useEffect(() => {
        initTime();
    }, []);

    const initTime = () => {
        setCurrentTime(`${moment().format('HH:mm')}`);
        setTimeout(() => initTime(), (60 - new Date().getSeconds() + 2) * 1000);
    }

    return (
        <Box background='#e6f3fe' justify="between" align="center" width={'full'}
            direction='row'
        >
            <Box direction="row" gap='small' align="center">
                <Box pad={'small'} style={{ left: 7 }} onClick={toSettingPage}>
                    <Menu size="large" color="#033076" fontWeight={'bold'} />
                </Box>
                <Box onClick={refresh} style={{ boxShadow: 'none', paddingLeft: 30, paddingRight: 30, paddingTop: 5, paddingBottom: 5 }}
                    round={{ size: 'medium' }}
                    background={COLOR.green}
                    border={{ color: COLOR.green, size: 'medium' }}
                >
                    <Text color={'#fff'} style={{ fontWeight: 'bold' }}>Refresh</Text>
                </Box>
            </Box>
            <Box pad={'small'} style={{ boxShadow: 'none' }}>
                <Text textAlign="center" color={'#033076'} style={{ fontWeight: 'bold', fontSize: 60 }}> {currentTime} </Text>
            </Box>
            <Box direction="row" gap='small' align="center">
                <Box onClick={help} style={{ boxShadow: 'none', paddingLeft: 30, paddingRight: 30, paddingTop: 5, paddingBottom: 5 }} round={{ size: 'medium' }}
                    background={COLOR.foreground_disabled}
                    border={{ color: COLOR.dark_blue, size: 'medium' }}
                >
                    <Text color={COLOR.dark_blue} style={{ fontWeight: 'bold' }}>AIDES</Text>
                </Box>
                <Box pad={'small'} onClick={toHistoryPage} style={{ boxShadow: 'none' }}>
                    <History size="large" color="#033076" />
                </Box>
            </Box>
        </Box>
    )
}

const getCategory = order => {
    if (order?.service_type_ref?.includes("::")) {
        return order.service_type_ref.split("::")[1];
    }
    let sku = (order?.items || []).find(s => s.sku_ref);
    if (!sku) return "inconnu";

    let sku_ref = sku.sku_ref;

    let allCats = getAllCatsData();
    for (let cat of allCats) {
        for (let plat of cat.plats) {
            if (plat.ref === sku_ref) {
                let name = cat.name;
                let locationName = getLocation()?.name || '';
                return name.replace('- ' + locationName, '').replace(locationName + ' -').trim();
            }
        }
    }

    return "inconnu";
}

const HomePage = ({
    processingCommandes = [],
    terminatedCommandes = [],
    processingCommande,
    validateCommande,
    toHistoryPage,
    toSettingPage,
    showMessage
}) => {

    const [problem, setProblem] = React.useState();
    const [help, setHelp] = React.useState(false);
    const [selectedCommande, setSelectedCommande] = React.useState(processingCommande);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setSelectedCommande(processingCommande);
    }, [processingCommande]);

    const updateVersion = async () => {
        if (!isDispo()) return;
        let time = getLasUpdatedTime();
        let currentTime = new Date().getTime();
        let diff = (currentTime - time) / 1000;
        if (diff < 120) {
            showMessage("Veuillez attendre au moins 2m entre chaque rafraichir", 'warning');
            return;
        }
        try {
            setLoading(true)
            await syncCatalogs();
            window.location.href = config.baseAPI + '?reloaded=true';
            setLastUpdatedTime();
        } catch (error) {
            showMessage('Erreur inattendu', "error");
            console.log(error);
        }
        setLoading(false);
    }

    return (
        <BlockUi tag="div" blocking={loading}>
            <Box width="full" justify="center" align="center" >
                <Header
                    toHistoryPage={toHistoryPage}
                    toSettingPage={toSettingPage}
                    help={() => setHelp(true)}
                    refresh={updateVersion}
                />

                {help &&
                    <Layer style={{ background: 'transparent' }}>
                        <Box width={'xlarge'} background={COLOR.foreground_disabled} justify='center' align="center" pad={'medium'} gap='medium'
                            round={{ size: 'medium' }}
                        >
                            <Box style={{ position: 'absolute', left: 10, top: 10 }} onClick={() => setHelp(false)}
                                round={{ size: 'medium' }} border={{ size: 'small', color: COLOR.dark_blue }}
                                pad='small'
                            >
                                <Close color={COLOR.background_disabled} size='small' />
                            </Box>
                            <Box width={'full'} background={COLOR.light_blue} round={{ size: 'medium' }} justify="center" align="center"
                                style={{ padding: 10 }}
                            >
                                <Text color={COLOR.dark_blue} style={{ fontWeight: 'bold', fontSize: FONT_SIZE.medium }} >COMMANDES EN COURS</Text>
                            </Box>
                            <Box style={{ padding: 5 }} width='full' direction="row" align="center" gap="xsmall">
                                <Text color={COLOR.dark_blue} style={{ fontSize: FONT_SIZE.medium }}>
                                    {` Cliquez sur `} </Text>
                                <Box style={{ boxShadow: 'none', paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5 }} round={{ size: 'medium' }}
                                    background={COLOR.foreground_disabled}
                                    border={{ color: COLOR.dark_blue, size: 'medium' }}
                                >
                                    <Text color={COLOR.dark_blue} style={{ fontWeight: 'bold' }}>Problèmes ?</Text>
                                </Box>
                                <Text color={COLOR.dark_blue} style={{ fontSize: FONT_SIZE.medium }}>
                                    depuis la commande concernée pour <b>contacter le support dédié.</b></Text>
                            </Box>
                            <Box width={'full'} background={COLOR.light_blue} round={{ size: 'medium' }} justify="center" align="center"
                                style={{ padding: 10 }}
                            >
                                <Text color={COLOR.dark_blue} style={{ fontWeight: 'bold', fontSize: FONT_SIZE.medium }} >QUESTIONS</Text>
                            </Box>
                            <Box style={{ padding: 5 }} width='full' gap="medium">
                                <Text color={COLOR.dark_blue} style={{ fontSize: FONT_SIZE.medium, fontWeight: 'bold' }}>
                                    Du lundi au vendredi du 09h à 18h : <span style={{ fontSize: FONT_SIZE.large }}>0985405314</span>
                                </Text>
                            </Box>
                            <Box width={'full'} background={COLOR.light_blue} round={{ size: 'medium' }} justify="center" align="center"
                                style={{ padding: 10 }}
                            >
                                <Text color={COLOR.dark_blue} style={{ fontWeight: 'bold', fontSize: FONT_SIZE.medium }} >AUTRES</Text>
                            </Box>
                            <Box style={{ padding: 5 }} width='full' gap="medium">
                                <Text color={COLOR.dark_blue} style={{ fontSize: FONT_SIZE.medium }}>{` Remplir ce formulaire: `}
                                    <a href="https://forms.gle/6R7x6LwYHPnH2UfG6" target={'_blank'} style={{ textDecoration: 'none', color: COLOR.dark_blue }}><b>https://forms.gle/6R7x6LwYHPnH2UfG6</b></a>
                                </Text>
                                <Text color={COLOR.dark_blue} style={{ fontSize: FONT_SIZE.medium }}>{` Contactez-nous via : `}
                                    <b>partenaire@meor.fr</b>
                                </Text>
                            </Box>

                        </Box>
                    </Layer>
                }
                {problem &&
                    <Layer style={{ background: 'transparent' }}>
                        <Box width={'large'} background={COLOR.dark_blue} justify='center' align="center" pad={'small'} gap='medium'
                            round={{ size: 'medium' }}
                            style={{ height: 500 }}
                        >
                            <Box style={{ position: 'absolute', left: 10, top: 10 }} onClick={() => setProblem(undefined)} >
                                <Close color={COLOR.light_blue} size='large' />
                            </Box>
                            <Text color={COLOR.light_blue} textAlign='center' style={{ fontSize: FONT_SIZE.large }}>
                                Numéro assistance: <br /><br />
                                {isDeliveroo(problem) ? '09 77 55 03 31' : '08 05 08 42 86'}
                            </Text>
                            <br />
                            <Text color={COLOR.light_blue} textAlign='center' style={{ fontSize: FONT_SIZE.large }}>
                                Code restaurant:<br /><br /> {getCategory(problem)}
                            </Text>
                            <br />
                            <Text color={COLOR.light_blue} textAlign='center' style={{ fontSize: FONT_SIZE.large }}>
                                Numéro de commande: <br /><br /> {problem?.collection_code}
                            </Text>
                        </Box>
                    </Layer>
                }
                {processingCommandes.length === 0 && terminatedCommandes.length === 0 ?

                    <Box width='full' align="center" justify="center" alignContent="center" alignSelf="center" style={{ minHeight: 200 }}>
                        <Text style={{ fontSize: 60 }} textAlign="center" color={'#033076'}>Les commandes s'afficheront ici</Text>
                    </Box> :

                    <Box width="full" justify="center" align="center" pad={'small'} direction='row' gap="medium">
                        <Box style={{ width: '50%' }} justify="center" >
                            <Box style={{ height: 360 }}>
                                <CommandeDisplay
                                    title={"En prépation"}
                                    commandes={processingCommandes}
                                    onSelect={c => setSelectedCommande(c)}
                                    selectedCommande={selectedCommande}
                                    onProblemClick={c => setProblem(c)}
                                />
                            </Box>
                            <Box style={{ height: 20 }} />
                            <Box style={{ height: 360 }}>
                                <CommandeDisplay
                                    title={"Terminée"}
                                    commandes={terminatedCommandes}
                                    onSelect={c => setSelectedCommande(c)}
                                    selectedCommande={selectedCommande}
                                    onProblemClick={c => setProblem(c)}
                                />
                            </Box>

                        </Box>
                        <Box style={{ width: '50%' }} justify="center" align="center">
                            {selectedCommande &&
                                <ProcessingCommande
                                    setLoading={setLoading}
                                    commande={selectedCommande}
                                    onValidate={validateCommande}
                                    showMessage={showMessage}
                                />
                            }
                        </Box>
                    </Box>

                }
            </Box>
        </BlockUi>
    )
}

const CommandeDisplay = ({ commandes, title, selectedCommande, onSelect, onProblemClick }) => {

    const isSelected = c => c.id === selectedCommande?.id;
    const getBgColor = c => isSelected(c) ? COLOR.dark_blue : COLOR.light_blue;
    const getFrColor = c => isSelected(c) ? COLOR.light_blue : COLOR.dark_blue;
    const getProvider = c => isDeliveroo(c) ? deliveroo : ubereat;
    const getCommandeTime = c => moment(c.created_at).format('HH:mm');

    const [expectedTimes, setExpectedTimes] = useState([]);
    const [timeoutId, setTimeoutId] = useState();

    const reloadDeliverTime = () => {
        setExpectedTimes([...expectedTimes]);
        const id = setTimeout(reloadDeliverTime, 60 * 1000);
        setTimeoutId(id);
    }

    useEffect(() => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        if (commandes.length) {
            const _expectedTimes = commandes.map(c => ({ id: c.id, expectedTime: c.expected_time }));
            setExpectedTimes(_expectedTimes);
        }
    }, [commandes]);

    const getExpectedTime = c => {
        const command = expectedTimes.find(e => e.id === c.id);
        if(!command || ! command.expectedTime) return;
        const expected_time = command.expectedTime;
        if (moment().isBefore(moment(expected_time))) {
            return moment(expected_time).diff(moment(), 'minute') + 'min...';
        }
        return 'Arrivé';
    }

    return (
        <Box width={'full'} gap='small' style={{ height: 360, display: 'flex', flexDirection: 'column' }}>
            <Text style={{ fontWeight: 'bold', fontSize: 30 }} textAlign="center" color={COLOR.dark_blue}>{title} </Text>
            <Box style={{ overflowY: 'scroll' }} gap='xsmall'>
                {
                    commandes.map((c, index) => (
                        <div key={index} onClick={() => onSelect(c)} style={{
                            background: getBgColor(c), flexDirection: 'row', display: 'flex', borderRadius: 15, justifyContent: 'space-between',
                            paddingLeft: 10, paddingTop: 5, paddingBottom: 5
                        }}>
                            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10 }}

                            >
                                <Avatar src={getProvider(c)} style={{ height: 40, width: 40 }} />
                                <span style={{ fontSize: FONT_SIZE.medium, color: getFrColor(c) }}>
                                    {getTitle(c)}
                                </span>
                                <span style={{ fontSize: FONT_SIZE.medium, color: getFrColor(c) }}>
                                    {getCommandeTime(c)}
                                </span>
                            </div>
                            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, paddingRight: 10 }}>
                                <Box style={{ minHeight: 'auto' }} direction="row" gap="xsmall"
                                    justify="center" align="center"
                                >
                                    <Bike color={isSelected(c) ? COLOR.light_blue : COLOR.dark_blue} />
                                    <LinkNext size="small" color={isSelected(c) ? COLOR.light_blue : COLOR.dark_blue} />
                                    <Text color={isSelected(c) ? COLOR.light_blue : COLOR.dark_blue}>
                                        {getExpectedTime(c)}
                                    </Text>
                                </Box>
                                <Box onClick={() => onProblemClick(c)}
                                    style={{ boxShadow: 'none', paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5 }}
                                    round={{ size: 'medium' }}
                                    background={COLOR.foreground_disabled}
                                    border={{ color: COLOR.dark_blue, size: 'medium' }}
                                >
                                    <Text color={COLOR.dark_blue} style={{ fontWeight: 'bold' }}>Problèmes ?</Text>
                                </Box>
                            </div>
                        </div>
                    ))
                }
            </Box>
        </Box>
    )
}
export default HomePage;