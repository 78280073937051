import {
    Box
} from "grommet";
import React from "react";
const bgColor = '#e6f3fe';
const frontColor = '#033076';
const FullSeperator = ({color = frontColor}) => (
    <Box style={{ height: 1 }} border={{ color: color, side: 'bottom' }} width='xlarge' />
)

export default FullSeperator;