
import {
    Box, Button, Layer, Tab, Tabs, Text, TextInput
} from "grommet";
import { CaretDownFill, CaretRightFill, Close } from "grommet-icons";
import React, { useState } from "react";
import BlockUi from "react-block-ui";
import { getAllOptions, getCatalog, getInventory, getOption, syncCatalogs, updateInventory } from "../../services/service";
import { Search as SearchIcon } from 'grommet-icons';
import { COLOR, FONT_SIZE } from "../../services/utils";
import CustomToggle from "../elements/toggle";
import { getAllCatsData, getLocation } from "../../services/storage";

const isPlatHS = (plat, inventories) => {
    if (!inventories) return false;
    let hs = inventories.find(inv => inv.id === plat.id);
    return hs ? true : false;
}
const isOptionHS = (option, inventories) => {
    if (!inventories) return false;
    let hs = inventories.find(inv => inv.id === option.id);
    return hs ? true : false;
}

const containsInList = (item, arr) => arr.map(i => i.id).includes(item.id) ? true : false;

const getCatInv = (newInventories, category) =>
    newInventories.find(catInv => catInv.catId === category.id)?.inventory || [];

const Header = ({ onClose, onUpdate }) => {

    return (
        <Box background='#e6f3fe' justify="between" align="center" width={'full'}
            direction='row'
        >
            {onUpdate && <Box onClick={onUpdate} style={{ boxShadow: 'none', position: 'absolute', left: 30 }}  >
                <Text size="xlarge" color={COLOR.dark_blue}>Mettre à jour</Text>
            </Box>}
            <Box width={'full'} pad={'small'} >
                <Box style={{ boxShadow: 'none' }} alignSelf='center' pad={'small'}>
                    <Text style={{ fontSize: FONT_SIZE.large }} textAlign="center" color={'#033076'}> Disponibilité du plat </Text>
                </Box>
            </Box>
            <Box onClick={onClose} style={{ boxShadow: 'none', position: 'absolute', right: 30 }}  >
                <Text size="xlarge" color={COLOR.dark_blue}>Fermer et enregistrer</Text>
            </Box>
        </Box>
    )
}

const containsText = (main, sub) => (main || '').toLowerCase().includes((sub || '').toLowerCase());

const DISPLAY_TYPE = {
    dispo: 'dispo',
    indispo: 'indispo',
    both: 'both'
}

class DisponibilitePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            plats: [],
            allCats: [],
            query: '',
            inventories: [],
            newDispoInvs: [],
            newInDispoInvs: [],
            openConfirmation: false,
            allPlats: []
        }
    }

    componentDidMount = () => {
        let allCats = getAllCatsData();
        let allPlats = [];
        if (allCats.length) {
            for (let cat of allCats) {
                let catData = { id: cat.id, name: cat.name };
                allPlats = allPlats.concat(cat.plats.map(p => ({ ...p, category: catData })));
                cat.plats.forEach(p => p.category = catData);
            }
            this.setState({ allCats, allPlats });
        }
        this.loadAllInventories(allCats, allPlats);

    }

    loadAllInventories = async (allCats, allPlats) => {
        let inventories = [];
        let allPlatsIds = allPlats.map(s => s.id);
        let allOptionIds = [];
        for(let plat of allPlats) {
            for(let o1 of plat.options) {
                for(let o2 of o1.options) {
                    allOptionIds.push(o2.id);
                }
            }
        }
        for (let cat of allCats) {
            let res = await getInventory(cat.id);
            let inventory = res.data;
            inventory.forEach(v => {
                v.stock = parseInt(parseFloat(v.stock).toFixed(0));
                v.id = v.option_id || v.sku_id
                v.catId = cat.id;
            });
            inventories = inventories.concat(inventory.filter(s => allOptionIds.includes(s.option_id) || allPlatsIds.includes(s.id)));
        }

        this.setState({ inventories });
    }

    search = (query) => {
        if (!query) {
            return;
        }
        let plats = this.state.allPlats;
        plats = JSON.parse(JSON.stringify(plats));
        plats = plats.filter(p => {
            let found = containsText(p.ref, query) ||
            containsText(p.name, query);
            if(found) return true;
            let options = (p.options || []).filter(o => {
                let found = containsText(o.name, query) || containsText(o.ref, query);
                if(found) return true;

                let subOptions = (o.options || []).filter(suo => containsText(suo.name, query));
                o.options = subOptions;
                return subOptions.length;
            });
            p.options = options;
            return options.length;
        });
        let plat = plats.pop();
        let results = [plat, ...plats];
        this.setState({ plats: results });
    }

    onLoadOptions = async (plat) => {
    }

    updateInventoryAndClose = async () => {
        this.setState({ loading: true });
        let isOk = false;
        try {
            let currentInventories = this.state.inventories.map(s => ({ ...s }));
            let newInventories = [];
            for (let indispo of currentInventories) {
                let catInv = newInventories.find(catItem => catItem.catId === indispo.catId);
                if (!catInv) newInventories.push({ catId: indispo.catId, inventory: [] })
            }

            for (let item of this.state.newDispoInvs) {
                let catId = item.category.id;
                if (item.option_list_id) { // is option
                    currentInventories = currentInventories.filter(inv => (inv.catId === catId && inv.option_id !== item.id) || inv.catId !== catId);
                } else { // is sku
                    currentInventories = currentInventories.filter(inv => (inv.catId === catId && inv.sku_id !== item.id) || inv.catId !== catId);
                }
            }

            for (let item of this.state.newInDispoInvs) {
                let catId = item.category.id;
                if (item.option_list_id) { // is option
                    currentInventories.push({ catId, option_id: item.id })
                } else { // is sku
                    currentInventories.push({ catId, sku_id: item.id });
                }
            }

            for (let inv of currentInventories) {
                let catId = inv.catId;
                let catInv = newInventories.find(catItem => catItem.catId === catId);
                if (!catInv) {
                    catInv = { catId, inventory: [] };
                    newInventories.push(catInv);
                }
                catInv.inventory.push({ sku_id: inv.sku_id, option_id: inv.option_id, stock: 0 });
            }
            for (let catInv of newInventories) {
                try {
                    await updateInventory(catInv.catId, catInv.inventory);
                    isOk = true;
                } catch (error) {
                    console.log('error: ', error);
                    isOk = false;
                }
                if(!isOk) break;
            }
            this.props.updateInventoryHook(isOk);

            this.props.onClose();
            this.setState({ loading: false });
        } catch (err) {
            console.log(err);
            this.props.onClose();
            this.setState({ loading: false });
            this.props.updateInventoryHook(isOk);
        }

    }

    onDispoChange = (newDispoInvs) => {
        this.setState({ newDispoInvs: newDispoInvs.map(s => s) });
    }

    onInDispoChange = (newInDispoInvs) => {
        this.setState({ newInDispoInvs: newInDispoInvs.map(s => s) });
    }

    selectTab = async index => {
    }

    onClose = () => {
        let changed = this.state.newDispoInvs.length || this.state.newInDispoInvs.length;
        if (!changed) {
            this.props.onClose();
        } else {
            this.setState({ openConfirmation: true });
        }
    }

    updateCatalogs = async () => {
        this.setState({ loading: true });
        try {
            await syncCatalogs();
            this.props.showMessage('Mis à jour avec succès!', 'success');
        } catch (error) {
            this.props.showMessage('Erreur inattendu! Veuillez contacter le support.', 'error');
        }
        this.props.onClose();
        this.setState({ loading: false });
    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading}>
                <Box width={'full'}>
                    <Header onClose={this.onClose} 
                        onUpdate={this.updateCatalogs}
                    />
                    <Search
                        onSearch={this.search}
                        query={this.state.query}
                        setQuery={query => this.setState({ query })}
                    />
                    {this.state.openConfirmation &&
                        (<Layer style={{ background: 'transparent' }}>
                            <Box background={COLOR.dark_blue} pad={'medium'} justify='center' align="center" gap="large" round={{ size: 'medium' }}>
                                <Box style={{ height: '100%' }} justify='center' align="center">
                                    {this.props.disable_inventory ?
                                        (<Text color={COLOR.light_blue} style={{ fontSize: FONT_SIZE.medium }} textAlign='center'>
                                            Veuillez attendre 1 minute entre chaque modification.
                                        </Text>) :
                                        (<Text color={COLOR.light_blue} style={{ fontSize: FONT_SIZE.medium }} textAlign='center'>
                                            Souhaitez-vous enregistrer <br /><br />
                                            les modifications suivantes ?
                                        </Text>)
                                    }
                                </Box>

                                {this.props.disable_inventory ?
                                    (<Box direction="row" justify="between" align="center" gap="medium">
                                        <Button label='Fermer' onClick={() => this.setState({ openConfirmation: false })}
                                            size='large'
                                            style={{ background: COLOR.dark_blue, color: COLOR.light_blue, border: 'none' }}
                                        />
                                    </Box>) :
                                    (<Box direction="row" justify="between" align="center" gap="medium">
                                        <Button label='NON' onClick={() => this.props.onClose()}
                                            size='large'
                                            style={{ background: COLOR.dark_blue, color: COLOR.light_blue, border: 'none' }}
                                        />
                                        <Button
                                            size="large"
                                            label='OUI' style={{ background: COLOR.light_blue, color: COLOR.dark_blue, border: 'none' }}
                                            onClick={this.updateInventoryAndClose}
                                        />
                                    </Box>)
                                }

                            </Box>
                        </Layer>)
                    }

                    {this.state.query && (
                        <Box width={'full'} style={{ paddingTop: 30 }}>
                            {this.state.plats
                                .map((plat, index) => (
                                    <CollapsiblePlat
                                        key={index}
                                        plat={plat}
                                        showFirst={index === 1 ? true : false}
                                        inventories={this.state.inventories}
                                        newDispoInvs={this.state.newDispoInvs}
                                        newInDispoInvs={this.state.newInDispoInvs}
                                        onDispoChange={this.onDispoChange}
                                        onInDispoChange={this.onInDispoChange}
                                    />
                                ))}
                        </Box>
                    )}
                    {!this.state.query && (
                        <Box width={'full'} style={{ paddingTop: 30 }}>
                            <Tabs onActive={(idx) => this.selectTab(idx)}>
                                <Tab key={112} title={<Text size="xxlarge" style={{ fontWeight: 'bold' }}>{'INDISPONIBLE'}</Text>}>
                                    <Box pad={'medium'} width='full' gap="small">
                                        <Box gap="small" width='full'>
                                            {this.state.allPlats.filter(p => isPlatHS(p, this.state.inventories.filter(i => i.catId === p.category.id)))
                                                .map((plat, index) => (
                                                    <CollapsiblePlat
                                                        key={index}
                                                        plat={plat}
                                                        inventories={this.state.inventories}
                                                        newDispoInvs={this.state.newDispoInvs}
                                                        newInDispoInvs={this.state.newInDispoInvs}
                                                        onInDispoChange={this.onInDispoChange}
                                                        onDispoChange={this.onDispoChange}
                                                    />
                                                ))}
                                        </Box>
                                    </Box>
                                </Tab>
                                {this.state.allCats.map((cat, id) => {
                                    let catName = cat.name.replace(`${getLocation().name}`, '')
                                        .replace('-', '')
                                        .trim();
                                    return (
                                        <Tab key={id} title={<Text size="xxlarge">{catName}</Text>}>
                                            <Box pad={'medium'} width='full' gap="small">
                                                <Box gap="small" width='full'>
                                                    {cat.plats.filter(p => !isPlatHS(p, this.state.inventories.filter(i => i.catId === cat.id)))
                                                        .map((plat, index) => (
                                                            <CollapsiblePlat
                                                                key={index}
                                                                plat={plat}
                                                                inventories={this.state.inventories}
                                                                newDispoInvs={this.state.newDispoInvs}
                                                                newInDispoInvs={this.state.newInDispoInvs}
                                                                onDispoChange={this.onDispoChange}
                                                                onInDispoChange={this.onInDispoChange}
                                                            />
                                                        ))}
                                                </Box>
                                            </Box>
                                        </Tab>
                                    )
                                })}
                            </Tabs>
                        </Box>
                    )}
                </Box>
            </BlockUi>
        )
    }
}

const CollapsiblePlat = ({ plat, onInDispoChange, onDispoChange, inventories, newInDispoInvs, newDispoInvs, showFirst=false }) => {

    const [expanded, setExpanded] = React.useState(showFirst);
    const onExpanded = () => {
        setExpanded(!expanded);
    }


    const _onDispoChange = (item, isDispo) => {
        let newList;
        if (isDispo) {
            if (containsInList(item, inventories)) {
                newDispoInvs.push(item);
                onDispoChange(newDispoInvs);
            } else {
                newList = newInDispoInvs.filter(i => i.id !== item.id);
                onInDispoChange(newList);
            }
        } else {
            if (containsInList(item, newDispoInvs)) {
                newList = newDispoInvs.filter(i => i.id !== item.id);
                onDispoChange(newList);
            } else {
                newInDispoInvs.push(item);
                onInDispoChange(newInDispoInvs);
            }
        }
        console.log(newDispoInvs, newInDispoInvs, newList);
    }

    return (
        <Box width={'full'} justify="center" style={{ boxShadow: 'none' }}>
            <Box direction="row" justify="between" width={'full'} pad='small' align="center">
                <Box direction="row" gap="small" onClick={onExpanded} style={{ boxShadow: 'none' }} justify='center' align="center" >
                    {!expanded ?
                        <CaretRightFill color={COLOR.light_blue} size="xlarge" /> :
                        <CaretDownFill fill="#c8c8c8" color={COLOR.light_blue} size="xlarge" />
                    }
                    <Text style={{ fontSize: FONT_SIZE.large }} color={COLOR.dark_blue}>{plat.ref || 'Inconnu'}</Text>
                </Box>
                <Box direction="row" align="center" justify="center" gap="small">
                    <CustomToggle
                        onChange={event => _onDispoChange(plat, event.target.checked)}
                        checked={containsInList(plat, newDispoInvs) || (!containsInList(plat, newInDispoInvs) && !containsInList(plat, inventories))}
                    />
                </Box>
            </Box>

            {expanded &&
                <Box width={'full'} pad='xsmall' justify="center" align="center">
                    {
                        (plat.options || []).map((option, index) => (
                            <PlatOption
                                key={index}
                                optionList={option}
                                onOptionDispoChange={_onDispoChange}
                                plat_dispo={containsInList(plat, newDispoInvs) || (!containsInList(plat, newInDispoInvs) && !containsInList(plat, inventories))}
                                inventories={inventories}
                                newDispoInvs={newDispoInvs}
                                newInDispoInvs={newInDispoInvs}
                                category={plat.category}
                            />
                        ))
                    }
                </Box>
            }
        </Box>
    )
}

const PlatOption = ({ optionList, onOptionDispoChange, inventories, plat_dispo, newInDispoInvs, newDispoInvs, category }) => {

    return (
        <Box width={'full'}
            style={{ paddingLeft: 100 }}
            gap='xsmall'
        >
            <Text size="xlarge" color={COLOR.dark_blue}>{optionList.name}</Text>
            {
                optionList.options.map((sub, index) => {
                    sub.category = { ...category };
                    return (
                        <Box style={{ paddingLeft: 40, width: '50%' }} key={index}>
                            <Box direction="row" gap="small" justify='between'>
                                <Text size="large" color={COLOR.dark_blue}>{sub.ref}</Text>
                                <CustomToggle
                                    onChange={event => onOptionDispoChange(sub, event.target.checked)}
                                    checked={plat_dispo && (containsInList(sub, newDispoInvs) || (!containsInList(sub, newInDispoInvs) && !containsInList(sub, inventories)))}
                                />
                            </Box>
                        </Box>
                    )
                })
            }
        </Box>
    )
}

const Search = ({ onSearch, setQuery, query }) => {
    return (
        <Box width={'full'}
            direction='row'
            pad={'small'}
            round={{ size: 'medium' }}
            border={{ size: 'small', color: '#033076' }}
        >
            <TextInput plain
                value={query}
                placeholder='Cherche un plat'
                size="xxlarge"
                icon={<SearchIcon />}
                style={{ boxShadow: 'none' }}
                onChange={event => {
                    setQuery(event.target.value);
                    onSearch(event.target.value);
                }} />

        </Box>
    )
}

export default DisponibilitePage;